import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import logo from "../images/logo.svg"
import "./header.sass"
import RegionSelector from "../components/regionSelector"

const NavLink = styled(Link)`
  color: white;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  text-decoration: none;
`

const H1 = styled.h1`
  display: flex;
  align-items: center;
`

const Logo = styled.img`
  max-width: 100%;
  @media (max-width: 450px) {
    max-width: 80%;
  }
`

const Header = ({ siteTitle, instagramUrl, region, menuLinks, fullscreenHandler }) => (
  <div className="header">
    <H1>
      <Link to={`/${region}`}>
        <Logo src={logo} alt={siteTitle} />
      </Link>
      {region &&
        <RegionSelector currentRegion={region} />
      }
    </H1>
    <nav>
      <ul>
        {menuLinks.map(link => (
          <li key={link.name}>
            <NavLink to={`/${region}${link.link}`}>{link.name}</NavLink>
          </li>
        ))}
        <li>
          <a
            href={instagramUrl}
            target="_blank"
            alt="Intagram link"
            rel="noopener noreferrer"
          >
            <span
              alt="Instagram link"
              style={{
                color: "white",
                fontSize: "12px",
                fontWeight: "bold",
                letterSpacing: ".8px"
              }}
            >IG.</span>
          </a>
        </li>
      </ul>
      <div className="controls">
        <button onClick={fullscreenHandler} className="open-nav">
          <span className="nav-icon"></span>
        </button>
      </div>
    </nav>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  instagramUrl: PropTypes.string,
  menuLinks: PropTypes.array,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
