const controls = `
<div class="plyr__controls">
    <div class="plyr__progress">
        <input data-plyr="seek" type="range" min="0" max="100" step="0.01" value="0" aria-label="Seek">
    </div>
    <button type="button" class="plyr__control" data-plyr="prev">
        <svg class="icon--not-pressed" role="presentation"><use xlink:href="#hamlet-prev"></use></svg>
    </button>
    <button type="button" class="plyr__control" data-plyr="next">
        <svg class="icon--not-pressed" role="presentation"><use xlink:href="#hamlet-next"></use></svg>
    </button>
    <button type="button" class="plyr__control" data-plyr="fullscreen">
        <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-exit-fullscreen"></use></svg>
        <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-enter-fullscreen"></use></svg>
    </button>
</div>
<button type="button" class="plyr__control plyr__control--overlaid plyr__tab-focus" data-plyr="play" aria-label="Play"><svg role="presentation" focusable="false"><use xlink:href="#hamlet-large-play"></use></svg><span class="plyr__sr-only">Play</span></button>
`

export default controls
