/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"

import Header from "./header"
import "plyr/dist/plyr.css"
import "./layout.css"
import "./layout.sass"
import Player from "./player"

const FullscreenMenu = styled.div`
  height: 100%;
  opacity: ${props => (props.open ? .8 : 0)};
  width: 100%;
  background: #000;
  top: 0;
  left: 0;
  position: fixed;
  z-index: ${props => (props.open ? 110 : -1)};
  border: 10px solid #fff;
`

const Layout = ({ region, children, video, close, list }) => {
  const [fullscreen, setFullscreen] = useState(false)
  const clickHandler = event => {
    const path = event.target.href
    if (path.includes(window.location.pathname)) {
      setFullscreen(false)
    }
  }
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          instagramUrl
          menuLinks {
            name
            link
          }
        }
      }
    }
  `)

  return (
    <>
      <FullscreenMenu className="fullscreen-wrapper" open={fullscreen}>
        {fullscreen && (
          <Header
            menuLinks={data.site.siteMetadata.menuLinks}
            siteTitle={data.site.siteMetadata.title}
            instagramUrl={data.site.siteMetadata.instagramUrl}
            region={region}
            fullscreenHandler={event => {
              setFullscreen(!fullscreen)
            }}
          />
        )}
        <ul className="fullscreen-menu">
          {data.site.siteMetadata.menuLinks.map(link => {
            return (
              <li key={`/${region}${link.name}`}>
                <Link onClick={clickHandler} to={`/${region}${link.link}`}>
                  {link.name}
                </Link>
              </li>
            )
          })}
          <li><a target="_blank" alt="Intagram link" rel="noopener noreferrer" href={data.site.siteMetadata.instagramUrl}>IG.</a></li>
        </ul>
      </FullscreenMenu>
      {!fullscreen && (
        <Header
          menuLinks={data.site.siteMetadata.menuLinks}
          region={region}
          siteTitle={data.site.siteMetadata.title}
          instagramUrl={data.site.siteMetadata.instagramUrl}
          fullscreenHandler={e => {
            setFullscreen(!fullscreen)
          }}
        />
      )}
      <div className="layout-wrapper">
        <main>
          {video && <Player region={region} close={close} videoInit={video} list={list} />}
          {children}
        </main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  close: PropTypes.func,
  video: PropTypes.object,
}

export default Layout
