import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import closeIcon from "../images/close.svg"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import slugify from "slugify"
import styled from "styled-components"
import sprite from "../images/plyr.svg"
import _ from "underscore"
import controls from "../components/controls"
import "./player.css"
import "./player.sass"

const PlayerWrapper = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  width: 100%;
  z-index: 999;
`

const Close = styled.div`
  background: none;
  border: 0;
  margin-top: 2px;
  cursor: pointer;
`

const handleResize = () => {
  let vh = window.innerHeight * 0.01
  const playerWrapper = document.querySelector('.player-wrapper')
  if (playerWrapper) {
    playerWrapper.style.setProperty('--vh', `${vh}px`)
  }
}

const directorLink = (region, video) => {
  if (region === "ext" || video.director?.region?.filter(region => region.slug === 'ext').length > 0) {
    return <span>{video.director.name}</span>
  }
  return (
    <Link
      to={`/${region}/directors/${slugify(video.director.name, {
        lower: true,
      })}`}
    >
      {video.director.name}
    </Link>
  )
}

const Player = ({ region, videoInit, close, list }) => {
  const [video, setVideo] = useState(videoInit)
  useEffect(() => {
    handleResize()
    const Plyr = require("plyr")
    const plyr = new Plyr(document.getElementById("plyr-video"), {
      controls: controls,
      iconUrl: sprite,
    })
    const getIndex = (url) => {
      const index = list.indexOf(list.find(item => item.url === url))
      return index
    }
    const next = () => {
      const index = getIndex(plyr.source)
      const next = index + 1 === list.length ? list[0] : list[index + 1]
      loadVideo(next.url)
      setVideo(next)
    }
    const prev = () => {
      const index = getIndex(plyr.source)
      const prev = index - 1 < 0 ? list[list.length - 1] : list[index - 1]
      loadVideo(prev.url)
      setVideo(prev)
    }
    const loadVideo = url => {
      plyr.source = {
        type: "video",
        sources: [
          {
            src: url,
            type: "video/mp4",
            size: 1080,
          },
        ],
      }
    }
    const header = document.querySelector("div.video-header")
    const toggleHeader = e => {
      const header = e.target
      if (header.classList.contains("over")) {
        header.classList.remove("over")
      } else {
        header.classList.add("over")
      }
    }
    header?.addEventListener("mouseover", toggleHeader)
    header?.addEventListener("mouseout", toggleHeader)
    plyr.on("controlshidden", () => {
      if (!header?.classList.contains("over")) {
        header.classList.add("hide")
      }
    })
    plyr.on("controlsshown", () => {
      header?.classList.remove("hide")
    })
    plyr.on("ready", () => {
      const nextBtn = document.querySelector('button[data-plyr="next"]')
      const prevBtn = document.querySelector('button[data-plyr="prev"]')
      nextBtn?.addEventListener(
        "click",
        _.debounce(() => {
          next()
        }, 200)
      )
      prevBtn?.addEventListener(
        "click",
        _.debounce(() => {
          prev()
        }, 200)
      )
      var playPromise = plyr.play()
      playPromise?.then(_ => {}).catch(e => {
        console.log("Playing failed")
        console.log(e)
      })
    })
    plyr.on("ended", () => {
      next()
    })
    window.player = plyr
    window.addEventListener("resize", _.debounce(handleResize, 100))
    const keyHandler = event => {
      if (window.player === "undefined") {
        return
      }
      if (event.key === "Escape") {
        close()
      }
    }
    document.addEventListener("keydown", keyHandler)
    document.addEventListener("keydown", keyHandler)
    return () => {
      window.removeEventListener("resize", _.debounce(handleResize, 100))
      document.removeEventListener("keydown", keyHandler)
      header?.removeEventListener("mouseover", toggleHeader)
      header?.removeEventListener("mouseout", toggleHeader)
    };
  }, [video])
  return (
    <PlayerWrapper className="player-wrapper">
      <Helmet htmlAttributes={{ class: "player" }} />
      { video &&
      <>
        <video id="plyr-video" width="1920" height="1080" preload="none">
        <source src={videoInit.url} type="video/mp4" size="1080" />
        </video>
        <div>
          <div className="video-header">
            <div>
              {video.director &&
                directorLink(region, video)
              }
            </div>
            <div style={{ fontSize: `15px` }}>
              <span>{video?.brand}</span> — {video?.title}
            </div>
            <Close className="close"
              onClick={() => {
                close()
              }}
            >
              <img src={closeIcon} style={{ margin: `0` }} alt="close" />
            </Close>
          </div>
        </div>
      </>
      }
    </PlayerWrapper>
  )
}

Player.propTypes = {
  close: PropTypes.func.isRequired,
  videoInit: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
}

export default Player
